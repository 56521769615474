import PropTypes from "prop-types"
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const AboutPage = ({ location }) => {
  return (
    <Layout location={location} title={"← About Me"}>
      <SEO title="About" />
      <article itemScope itemType="http://schema.org/Article">
        <p>
          My name is Michael Ishoel Olsen and I&apos;m a software developer from
          Norway.
        </p>
        <p>
          My primary languages are Java, Kotlin, React, Typescript and SCSS. I
          also have professional experience with AWS, GCP, Kubernetes, Openshift
          and Docker.
        </p>
        <p>Feel free to reach out to me on my email.</p>
      </article>
    </Layout>
  )
}

AboutPage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
}

export default AboutPage
