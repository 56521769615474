import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

const Layout = ({ location, title, children, fromBlog }) => {
  // eslint-disable-next-line
  const rootPath = `${__PATH_PREFIX__}/`
  let header
  if ([rootPath].includes(location.pathname)) {
    header = (
      <h2
        style={{
          marginBottom: "50px",
          marginTop: 0,
        }}
      >
        <Link
          style={{
            boxShadow: `none`,
            color: `inherit`,
          }}
          to={`/`}
        >
          {title}
        </Link>
      </h2>
    )
  } else {
    header = (
      <h2
        style={{
          marginTop: 0,
        }}
      >
        <Link
          style={{
            boxShadow: `none`,
            color: `inherit`,
          }}
          to={fromBlog ? `/blog` : `/`}
        >
          {title}
        </Link>
      </h2>
    )
  }
  return (
    <div
      style={{
        marginLeft: `auto`,
        marginRight: `auto`,
        maxWidth: "700px",
        padding: "20px",
      }}
    >
      {location.pathname !== rootPath && <header>{header}</header>}
      <main>{children}</main>
      {/* <footer>© {new Date().getFullYear()}</footer> */}
    </div>
  )
}

Layout.propTypes = {
  location: PropTypes.object,
  title: PropTypes.string,
  children: PropTypes.node,
  fromBlog: PropTypes.bool,
}

export default Layout
